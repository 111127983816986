<template>
  <mds-tag on-black>
    {{ envName }}
  </mds-tag>
</template>

<script>
import { MdsTag } from '@mds/tag';

export default {
  name: 'EnvironmentTag',
  components: {
    MdsTag,
  },
  computed: {
    envName () {
      return (process.env.NODE_ENV || 'development').toUpperCase();
    },
  },
  mounted () {
    const environment = process.env.VUE_APP_SHORT_ENV ?? 'DEV';
    document.title = `[${environment}] DAP Crawler`;
  },
};
</script>
